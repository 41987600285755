<body>
  <div class="contenedorr" style="background-color: #2d313e; color: white; width: 100%; height: 70px; display: flex; justify-content: center; align-items: center;">
      <div>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            Konnecta System 7.0
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="account-pages d-flex align-items-center justify-content-center" style="min-height: calc(100vh - 70px - 50px);">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-12 col-lg-12 col-xl-12">
          <div class="card contenedor overflow-hidden background-login">

            <div class="card-group">
              <div class="card" style="background-color: #fff; border-color: #fff;">
                <div class="d-flex justify-content-center align-items-center h-100">
                  <img src="../../../../assets/images/logoKonnectaDark.png" class="img-fluid rounded-start" style="max-width: 85%;" alt="...">
                </div>
              </div>
              <div class="card" style="background-color: #2d313e; border-color: #2d313e;">
                <div class="card-body p-4">
                  <div class="text-center mt-2">
                    <h5 style="color: #06f0fb;">Bienvenido !</h5>
                    <p style="color: rgb(161, 161, 161);">Inicia sesión para continuar.</p>
                  </div>
                  <div class="p-2 mt-4">
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                      @if(error)
                      { <ngb-alert type="danger" [dismissible]="false">{{ error }}</ngb-alert> }
                      <div class="mb-3">
                        <label for="username" style="color: rgb(161, 161, 161);">Usuario</label>
                        <input type="username" formControlName="username" class="form-control" id="username" placeholder="Email" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                        @if(submitted && f.username.errors){
                        <div class="invalid-feedback">
                          @if(f.username.errors.required){
                          <div>Correo electrónico es requerido</div>}
                          @if(f.username.errors.username){
                          <div>El correo electrónico debe ser una dirección de correo electrónico válida.</div>}
                        </div>}
                      </div>

                      <div class="mb-3">
                        <div class="float-end">
                          <a href="/account/reset-password" style="color: rgb(161, 161, 161);" class="text-muted">Contraseña olvidada?</a>
                        </div>
                        <label for="password" style="color: rgb(161, 161, 161);">Contraseña</label>
        <input [type]="type" formControlName="password" class="form-control" id="password" placeholder="Contraseña" />
                        <span *ngIf="type == 'password'" style="color: rgb(161, 161, 161);" class="eyeButton" (click)="myFunctionPasswordCurrent()">
                          <i class="fas fa-eye"></i>
        </span>
                        <span *ngIf="type == 'text'" style="color: rgb(161, 161, 161);" class="eyeButton" (click)="myFunctionPasswordCurrent()">
                          <i class="fas fa-eye-slash"></i>
        </span>
                        @if(submitted && f.email.errors){
                        <div class="invalid-feedback">
                          @if(f.email.errors.required){
                          <div>password is required</div>}
                        </div>}
                      </div>

                      <div class="mt-3 text-end">
                        <button class="btn btn-new descrip w-sm waves-effect waves-light" [disabled]="loginForm.invalid" style="width: 30%;" type="submit">
          <div class="justigy">
            <span [@fadeInUp] *ngIf="loading" class="loader"></span>
            <span *ngIf="!loading">Ingresar</span>
            <span style="margin-left: 5px;" *ngIf="loading">Cargando...</span>
          </div>
        </button>
                      </div>
      </form>
    </div>
        </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="contenedorr" style="background-color: #2d313e; color: white; position: absolute; bottom: 0; width: 100%; height: 70px; display: flex; justify-content: center; align-items: center;">
    <div>
      <div class="container">
    <div class="row">
      <div class="col-lg-12 text-center">
        Copyright 2024 © Konnecta System 7.0 Todos los derechos reservados.
      </div>
    </div>
      </div>
    </div>
  </div>
</body>